import PropTypes from 'prop-types';
export const DRAWER_DIRECTION_BTT = 'BottomToTop';
export const DRAWER_DIRECTION_LTR = 'LeftToRight';
export const DRAWER_DIRECTION_RTL = 'RightToLeft';
export const DRAWER_DIRECTION_TTB = 'TopToBottom';
export const DRAWER_PROP_TYPES = {
  children: PropTypes.node,
  direction: PropTypes.oneOf([DRAWER_DIRECTION_BTT, DRAWER_DIRECTION_LTR, DRAWER_DIRECTION_RTL, DRAWER_DIRECTION_TTB]),
  onOverlayClick: PropTypes.func,
  trigger: PropTypes.string,
  withOverlay: PropTypes.bool,
};