import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';

import { isServer } from '../../utils/isServer';
import styles from './Drawer.module.scss';
import { DRAWER_DIRECTION_LTR, DRAWER_PROP_TYPES } from './Drawer.definitions';

const Drawer = React.memo(
  ({
    classList = {},
    children,
    direction = DRAWER_DIRECTION_LTR,
    onOverlayClick,
    isNested = false,
    trigger,
    onOverlayOpened,
    withOverlay = true,
  }) => {
    const [shouldBeOpen, setShouldBeOpen] = useState(false);
    const [shouldRender, setShoulRender] = useState(false);
    const { hash } = useLocation();

    useEffect(() => {
      if (!isServer) {
        setShoulRender(true);
      }
    }, [isServer]);

    useEffect(() => {
      if (hash.startsWith(`#${trigger}`)) {
        setShouldBeOpen(true);
      }

      return () => {
        setShouldBeOpen(false);
      };
    }, [trigger, hash]);

    useEffect(() => {
      if (shouldBeOpen && onOverlayOpened) onOverlayOpened();
    }, [onOverlayOpened, shouldBeOpen]);

    useEffect(() => {
      if (shouldBeOpen) {
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
      }

      return () => {
        document.body.style.overflow = 'initial';
        document.body.style.touchAction = 'auto';
      };
    }, [shouldBeOpen]);

    const transitionDirection = shouldBeOpen ? 'Out' : 'In';

    const [currentOverlay, setCurrentOverlay] = useState(`transition${transitionDirection}Fx`);
    const [currentDrawer, setCurrentDrawer] = useState(`transition${transitionDirection}Fx`);

    if (isServer) return <div />;

    const rootElm = document.querySelector('body');

    const handleOverlayTransitionEnd = () => {
      const transitionDirection = shouldBeOpen && hash === `#${trigger}` ? 'Out' : 'In';
      setCurrentOverlay(`transition${transitionDirection}Fx`);
    };

    const handleDrawerTransitionEnd = () => {
      const transitionDirection = shouldBeOpen ? 'Out' : 'In';
      setCurrentDrawer(`transition${transitionDirection}Fx`);
    };

    const handleOverlayClick = () => {
      if (typeof onOverlayClick === 'function') {
        onOverlayClick();
      }
    };

    const overlayClasses = classnames(
      styles.root,
      styles[currentOverlay],
      {
        [styles.open]: shouldBeOpen,
      },
      {
        [styles.rootNested]: isNested,
      }
    );

    const drawerClasses = classnames(
      styles.drawer,
      styles[currentDrawer],
      styles[`direction${direction}`],
      classList.root,
      {
        [styles.open]: shouldBeOpen,
      },
      {
        [styles.drawerNested]: isNested,
      }
    );

    return shouldRender ? (
      createPortal(
        <>
          {withOverlay && (
            <div
              className={overlayClasses}
              data-testid="drawerOverlay"
              onTransitionEnd={() => handleOverlayTransitionEnd()}
              onClick={handleOverlayClick}
            />
          )}
          <div className={drawerClasses} onTransitionEnd={() => handleDrawerTransitionEnd()}>
            {shouldBeOpen && children}
          </div>
        </>,
        rootElm
      )
    ) : (
      <div />
    );
  }
);

Drawer.displayName = 'Drawer';
Drawer.propTypes = DRAWER_PROP_TYPES;

export default Drawer;
